<script lang="ts" setup>
  import { BaseButton, Menu, Flag } from '@bt/design-system'

  import { availableLocales, setPathByLocale, selectedLocale } from '@/composables/languageSwitcher'

  import type { languagesT } from '@/types/General/Languages'

  const config = useRuntimeConfig()
  const { locale } = useI18n()

  defineProps<{
    items: any
  }>()

  const isLanguageSwitcherVisible = ref(false)
  const isSidebarMenuVisible = ref(false)

  const avLocales = computed(() =>
    availableLocales().map((l) => ({
      key: l.code as languagesT,
      title: l.title,
      isActive: l.isActive || false
    }))
  )

  const selectedLoc = computed(() => selectedLocale())

  const onMenuClick = (item: any) => {
    emit('change', item.currentItem)
  }

  const emit = defineEmits(['change', 'refreshAllData'])

  const toggleSidebarMenu = () => {
    isSidebarMenuVisible.value = !isSidebarMenuVisible.value
    isLanguageSwitcherVisible.value = false
  }

  const onLangChange = (value: string) => {
    toggleSidebarMenu()
    setPathByLocale(value)
    emit('refreshAllData', value)
  }
</script>

<template>
  <div class="mobile-main-nav">
    <NuxtLink :to="`${config.public.companyUrl}/${locale}/`">
      <img
        alt="airBaltic logo"
        class="bt-logo"
        height="19"
        src="/bt-logo.svg"
        title="airBaltic logo"
        width="81"
      />
    </NuxtLink>
    <MobileSidebar
      v-model="isSidebarMenuVisible"
      :is-sub-view-active="isLanguageSwitcherVisible"
      @close-sub-view="() => (isLanguageSwitcherVisible = false)"
    >
      <template #logo>
        <NuxtLink
          to="/"
          @click="() => (isSidebarMenuVisible = false)"
        >
          <img
            alt="airBaltic logo"
            class="bt-logo"
            height="27"
            src="/bt-logo.svg"
            title="airBaltic logo"
            width="120"
          />
        </NuxtLink>
      </template>
      <template #menu>
        <Menu
          :items="items"
          dividers
          width="full"
          @change="onMenuClick"
        />
      </template>
      <template #sub-view-content>
        <Menu
          width="full"
          dividers
          has-end-divider
          :items="avLocales"
        >
          <template #custom-menu-item="{ item, className }">
            <div
              class="lang-item"
              :class="className"
              @click="onLangChange(item.key)"
            >
              <Flag :code="item.key as languagesT" />
              {{ item.title }}
            </div>
          </template>
        </Menu>
      </template>
      <template #extra-content>
        <div class="mobile-lang-switch pl-4 gap-3">
          <i class="md-icon md-24 text-bt-brand-dark-blue"> language </i>
          <div @click="() => (isLanguageSwitcherVisible = true)">{{ selectedLoc.label }}</div>
        </div>
      </template>
    </MobileSidebar>

    <BaseButton
      icon="menu"
      type="text"
      @click="toggleSidebarMenu"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .mobile-main-nav {
    display: flex;
    justify-content: space-between;
    margin-top: rem(8);
    align-items: center;
  }

  .mobile-lang-switch {
    display: flex;
    align-items: center;
  }

  .lang-item {
    display: flex;
    gap: rem(16);
    align-items: center;
  }
</style>
