import { useAppStore } from '@/stores'
import type { languagesT } from '@/types/General/Languages'

interface LocaleI {
  code: languagesT
  dir: 'ltr' | 'rtl'
  files: string[]
  isActive?: boolean
  iso: string
  key: languagesT
  label: string
  title: string
}

const DEFAULT_LOCALE: LocaleI = {
  code: 'en',
  dir: 'ltr',
  files: ['locales/en.js'],
  iso: 'en-US',
  key: 'en',
  label: 'English',
  title: 'English'
}

function availableLocales(): LocaleI[] {
  const { $i18n } = useNuxtApp()
  const { locales, locale } = $i18n
  const appStore = useAppStore()

  return (locales.value as LocaleI[])
    .filter((loc: LocaleI) => loc.code && appStore.availableLocales.includes(loc.code))
    .map((loc: LocaleI) => ({ ...loc, isActive: loc.code === locale.value }))
}

function selectedLocale(): LocaleI {
  const { $i18n } = useNuxtApp()
  const { locales, locale } = $i18n
  const selected: LocaleI | undefined = (locales.value as LocaleI[]).find((loc) => loc.code === locale.value)

  return selected || DEFAULT_LOCALE
}

function setPathByLocale(value: string) {
  const { push } = useRouter()
  const appStore = useAppStore()
  const path = appStore.slugs[value] ?? `/${value}`

  push(path)
}

export { availableLocales, setPathByLocale, selectedLocale }
