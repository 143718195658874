import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAchievement, LazyBanner, LazyBannerBlock, LazyBannerWithIcon, LazyButton, LazyButtonAddToCalendar, LazyButtonDownload, LazyButtonFullWidth, LazyCard, LazyColumnElement, LazyContactRow, LazyCsvToJson, LazyDescriptionBlock, LazyEventCards, LazyFinancialReports, LazyGrayBlock, LazyHeading, LazyHeadingAndDescription, LazyImageBanner, LazyInvestorEvents, LazyLatestNewsAndAnnouncements, LazyPressBanner, LazyPressReleases, LazyProfileCard, LazyScopeAndAchievements, LazySideBySide, LazySingleFinancialReport, LazySlider, LazySocialMediaBlok, LazySocialMediaWrapper, LazyStructuredFinancialReports, LazyStructuredFinancialReportsAccordionWrapper, LazyStructuredFinancialReportsGroup, LazyStructuredFinancialReportsGroups, LazyThreeColumns, LazyTwoColumns, LazyUpcomingEvents, LazyVideoBanner, LazyBlockDivider, LazyContentHero, LazyHeroBanner, LazyCiStaticPage, LazyImageContent } from '#components'
const lazyGlobalComponents = [
  ["Achievement", LazyAchievement],
["Banner", LazyBanner],
["BannerBlock", LazyBannerBlock],
["BannerWithIcon", LazyBannerWithIcon],
["Button", LazyButton],
["ButtonAddToCalendar", LazyButtonAddToCalendar],
["ButtonDownload", LazyButtonDownload],
["ButtonFullWidth", LazyButtonFullWidth],
["Card", LazyCard],
["ColumnElement", LazyColumnElement],
["ContactRow", LazyContactRow],
["CsvToJson", LazyCsvToJson],
["DescriptionBlock", LazyDescriptionBlock],
["EventCards", LazyEventCards],
["FinancialReports", LazyFinancialReports],
["GrayBlock", LazyGrayBlock],
["Heading", LazyHeading],
["HeadingAndDescription", LazyHeadingAndDescription],
["ImageBanner", LazyImageBanner],
["InvestorEvents", LazyInvestorEvents],
["LatestNewsAndAnnouncements", LazyLatestNewsAndAnnouncements],
["PressBanner", LazyPressBanner],
["PressReleases", LazyPressReleases],
["ProfileCard", LazyProfileCard],
["ScopeAndAchievements", LazyScopeAndAchievements],
["SideBySide", LazySideBySide],
["SingleFinancialReport", LazySingleFinancialReport],
["Slider", LazySlider],
["SocialMediaBlok", LazySocialMediaBlok],
["SocialMediaWrapper", LazySocialMediaWrapper],
["StructuredFinancialReports", LazyStructuredFinancialReports],
["StructuredFinancialReportsAccordionWrapper", LazyStructuredFinancialReportsAccordionWrapper],
["StructuredFinancialReportsGroup", LazyStructuredFinancialReportsGroup],
["StructuredFinancialReportsGroups", LazyStructuredFinancialReportsGroups],
["ThreeColumns", LazyThreeColumns],
["TwoColumns", LazyTwoColumns],
["UpcomingEvents", LazyUpcomingEvents],
["VideoBanner", LazyVideoBanner],
["BlockDivider", LazyBlockDivider],
["ContentHero", LazyContentHero],
["HeroBanner", LazyHeroBanner],
["CiStaticPage", LazyCiStaticPage],
["ImageContent", LazyImageContent],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
