import { useBreakpointComposable } from '@bt/design-system'
import { ref } from 'vue'
import type { Ref } from 'vue'

export const useBreakpoints = (): { isMobile: Ref; isDesktop: Ref } => {
  if (process.client) {
    const { isMobile, isDesktop } = useBreakpointComposable()

    return {
      isMobile,
      isDesktop
    }
  }

  return {
    isMobile: ref(false),
    isDesktop: ref(false)
  }
}
