<script lang="ts" setup>
  import { Breadcrumb, Navigation } from '@bt/design-system'
  import { storeToRefs } from 'pinia'
  import { head } from 'lodash-es'
  import type CrumbsI from '@/types/Navigation/CrumbsI'
  import { useFetchHeaderMenu } from '@/composables/useFetchHeaderMenu'
  import { useBreakpoints } from '@/composables/useBreakpoints'
  import { useAppStore } from '@/stores'

  const { locale, t } = useI18n()
  const { push } = useRouter()
  const { fetchHeaderMenu } = useFetchHeaderMenu()
  const appStore = useAppStore()
  const { slugTranslations } = storeToRefs(appStore)
  const { isMobile } = useBreakpoints()
  const config = useRuntimeConfig()

  const emit = defineEmits(['refreshAllData'])

  const { data: headerNav } = await useAsyncData('headerNav', async () => await fetchHeaderMenu(locale.value))

  const navigation = ref(headerNav)

  defineProps<{
    hideBreadcrumbs?: boolean
  }>()

  const menuDataActive = computed(() => {
    if (!navigation.value) return {}
    return navigation.value.map((data: any) => {
      data.isActive = data.key === appStore.activeMainNav
      return data
    })
  })

  const breadCrumbs = computed(() => {
    let path = ''

    const crumbs: CrumbsI[] = [
      {
        title: t(`reusable.home`),
        key: config.public.companyUrl
      },
      {
        title: t('reusable.investorRelations'),
        key: '/'
      }
    ]

    if (appStore?.slugs[locale.value]?.length) {
      const slug = formatSbPath(appStore.slugs[locale.value], locale.value).split('/')

      slug.forEach((item: string) => {
        if (!item) return
        path = path === '' ? item : path + '/' + item

        crumbs.push({
          title: getSlugTranslation(item),
          key: path
        })
      })
    }

    return crumbs
  })

  const getSlugTranslation = (item: any) => {
    if (locale.value === 'en') {
      return getTitleFromSlug(item)
    }

    let translatedSlug

    slugTranslations.value.forEach((slug: any) => {
      if (head(Object.keys(slug)) === item) {
        translatedSlug = slug
      }
    })

    if (!translatedSlug) {
      return getTitleFromSlug(item)
    }

    return translatedSlug[item]
  }

  const getTitleFromSlug = (item: String) => {
    return item.replaceAll('-', ' ')
  }

  const onMenuClick = (path: any) => {
    return navigateTo(path.key, {
      external: true
    })
  }

  const onBreadCrumbClick = (item: any) => {
    if (item.key.startsWith('http')) {
      const path = item.key + '/' + locale.value
      return navigateTo(path, {
        external: true
      })
    }
    push(`/${locale.value}/${item.key}`)
  }

  const refreshAllData = (lang: string) => {
    emit('refreshAllData', lang)
  }
</script>

<template>
  <div class="header-wrapper content-wrapper">
    <div class="navigation-wrapper">
      <ClientOnly>
        <NavigationMobileHeader
          v-if="isMobile"
          :items="menuDataActive"
          @change="onMenuClick"
          @refresh-all-data="refreshAllData"
        />
        <Navigation
          v-else
          class="w-full"
        >
          <template #logo>
            <NuxtLink :to="`${config.public.companyUrl}/${locale}/`">
              <img
                alt="airBaltic logo"
                class="bt-logo"
                height="27"
                src="/bt-logo.svg"
                width="120"
              />
            </NuxtLink>
          </template>
          <template #items="{ activeClass, inactiveClass, defaultClass }">
            <NuxtLink
              v-for="item in menuDataActive"
              :key="item.key"
              :class="[defaultClass, item.isActive ? activeClass : inactiveClass]"
              :target="item.target || '_self'"
              :to="item.key"
            >
              {{ item.title }}
            </NuxtLink>
          </template>
          <template #right-side>
            <GeneralLanguageSwitcher @refresh-all-data="refreshAllData" />
          </template>
        </Navigation>
      </ClientOnly>
    </div>
    <div
      v-if="!isMobile && !hideBreadcrumbs"
      class="breadcrumbs"
    >
      <Breadcrumb
        :items="breadCrumbs"
        @change="onBreadCrumbClick"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .header-wrapper {
    border-bottom: 1px solid $border-grey;
    margin-bottom: rem(20);
    margin-top: 0;

    @include tablet-up {
      border: none;
      margin-bottom: 0;
    }
  }

  .navigation-wrapper {
    margin: auto rem(16) rem(8);

    @include tablet-up {
      margin: auto rem(16) rem(24);
    }

    @include desktop-up {
      margin: auto auto rem(40);
      justify-content: space-between;
      display: flex;
    }
  }

  .bt-logo {
    margin-top: rem(40);
  }

  .navigation-left {
    margin: rem(40) 0 0;
  }

  .breadcrumbs {
    margin: auto rem(16);

    @include desktop-up {
      margin: auto auto rem(16);
    }
  }
</style>
