import { defineStore } from 'pinia'
import { head } from 'lodash-es'
import { useFetchSlugTranslation } from '~/composables/useFetchSlugTranslation'

const { fetchSlugTranslation } = useFetchSlugTranslation()

const LOCALE_LIST = ['en', 'lv']

interface SlugI {
  [key: string]: string
}
interface AppStoreI {
  loading: boolean
  slugs: any
  activeMainNav: string
  cmsData: any
  menuData: any
  cmsPath: any
  cmsPathParams: any
  slugTranslations: SlugI[]
  errorPage: boolean,
  availableLocales: string[]
}

export const useAppStore = defineStore('app', {
  state: (): AppStoreI => ({
    loading: false,
    slugs: {},
    activeMainNav: '/',
    cmsData: {},
    menuData: [],
    cmsPath: '',
    cmsPathParams: {},
    slugTranslations: [],
    errorPage: false,
    availableLocales: LOCALE_LIST
  }),
  actions: {
    setCmsPath(path: string, queryParams = {}) {
      this.cmsPath = path
      this.cmsPathParams = queryParams
    },

    pushSlugTranslation(item: SlugI, defaultSlug: string, locale: string) {
      if (locale === 'en') return

      const spaceName = 'investors-airbaltic-com'

      const slug = defaultSlug.split('/')
      const path = item.path.split('/')
      let name = path.pop()

      slug.pop()

      if (name === '') {
        name = path.pop()
        slug.pop()
      }

      if (name === spaceName) return

      const translatedName = item.name

      if (!translatedName) return

      if (this.existsInSlugTranslations(name ?? '')) return

      this.updateSlugTranslations(name ?? '', translatedName)

      const nextItemName = path.pop()

      if (nextItemName === spaceName) return

      if (this.existsInSlugTranslations(nextItemName ?? '')) return

      this.getParentSlugTranslation(slug.join('/') + '/', nextItemName ?? '', locale)
    },

    existsInSlugTranslations(name: string) {
      let exists = false
      this.slugTranslations.forEach((slug: any) => {
        if (head(Object.keys(slug)) === name) {
          exists = true
        }
      })

      return exists
    },

    async getParentSlugTranslation(path: string, name: string, locale: string) {
      await fetchSlugTranslation(path).then((resp: any) => {
        resp.translated_slugs.forEach((slug: any) => {
          if (slug.lang === locale) {
            this.updateSlugTranslations(name, slug.name)
          }
        })
      })
    },

    updateSlugTranslations(key: string, value: string) {
      this.slugTranslations.push({
        [key]: value
      })
    }
  }
})
