<script lang="ts" setup>
  import '@bt/design-system/style.css'
  import { AccordionMenu, Menu, Text, OverlaySpinner } from '@bt/design-system'
  import { storeToRefs } from 'pinia'
  import { useAppStore } from '@/stores'
  import { useBreakpoints } from '@/composables/useBreakpoints'
  import StoryBlok from '@/services/storyblok'
  import { useFetchMenu } from '@/composables/useFetchMenu'
  import { useFetchFooter } from '@/composables/useFetchFooter'
  import formatSbPath from '@/utils/formatSbPath'

  const appStore = useAppStore()
  const { slugs, cmsData, cmsPath, menuData, loading, cmsPathParams, errorPage, activeMainNav } = storeToRefs(appStore)
  const { isDesktop } = useBreakpoints()
  const route = useRoute()
  const { push: routerPush } = useRouter()
  const { locales, locale, setLocale } = useI18n()
  const { fetchMenu } = useFetchMenu()
  const { fetchFooter } = useFetchFooter()
  const footerData = ref()

  cmsPath.value = route.path === `/${locale.value}` ? '' : route.path.replace(`/${locale.value}/`, '')

  if (errorPage.value) {
    cmsPath.value = activeMainNav.value
    errorPage.value = false
  }

  const { data, refresh } = await useAsyncData('cmsData', async () => await StoryBlok.pull(cmsPath.value, locale.value))
  cmsData.value = data

  const setSlugs = () => {
    locales.value.forEach((locale: any) => {
      if (locale.code === 'en') {
        slugs.value[locale.code] = `/${locale.code}/${formatSbPath(cmsData?.value?.default_full_slug)}`
      }
      const translatedSlug = cmsData?.value?.translated_slugs?.find((slug: any) => slug.lang === locale.code)

      if (translatedSlug) {
        const defaultSlug = cmsData?.value?.default_full_slug
        appStore.pushSlugTranslation(translatedSlug, defaultSlug, locale.code)
        slugs.value[locale.code] = `/${locale.code}/${formatSbPath(translatedSlug.path)}`
      }
    })
  }

  const onLocaleChange = (lang: string) => {
    if (lang === locale.value) return

    loading.value = true

    setLocale(lang).then(async () => {
      cmsPath.value = await refreshNuxtData()
      await getMenuData()
      await getFooterData()
      loading.value = false
    })
  }

  const getFooterData = async () => {
    footerData.value = await fetchFooter(locale.value)
  }

  const getMenuData = async () => {
    try {
      const data = await fetchMenu(locale.value)
      const currentView = formatSbPath(slugs.value[locale.value], locale.value)

      menuData.value = data.map((menuItem: any) => {
        if (menuItem.subItems) {
          menuItem.subItems = menuItem.subItems.map((subData: any) => ({
            ...subData,
            isActive: subData.key === currentView
          }))
        }

        menuItem.isActive = menuItem.key === (currentView || '/')

        return menuItem
      })
    } catch (e) {
      console.error(e)
    }
  }

  const activeMenu = computed(() => {
    let activeItem = ''
    menuData.value.forEach((menu) => {
      if (menu.subItems) {
        menu.subItems.forEach((sMenu) => {
          if (sMenu.isActive) {
            activeItem = sMenu.title
          }
        })
      }
      if (menu.isActive) {
        activeItem = menu.title
      }
    })

    return activeItem
  })

  const getPageData = (path: string) => {
    path = path?.currentItem?.key ?? path
    loading.value = true

    try {
      cmsPath.value = formatSbPath(path, locale.value)
      cmsData.value = []

      refresh().then(() => {
        setSlugs()
        getMenuData()
      })

      routerPush({ path: `/${locale.value}/${cmsPath.value}`, query: cmsPathParams.value })
    } catch (e) {
      console.error(e)
    }

    loading.value = false
  }

  getMenuData()
  setSlugs()
  getFooterData()

  watch(
    () => route.fullPath,
    () => {
      if (
        cmsPath.value &&
        cmsPath.value.replace(`/${locale.value}/`, '') === route.path.replace(`/${locale.value}/`, '')
      ) {
        return
      }

      appStore.setCmsPath(route.path, route.query)
      getPageData(cmsPath.value)
    },
    {
      deep: true
    }
  )

  useHead(() => {
    return {
      htmlAttrs: {
        lang: locale.value
      },
      script: [
        {
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': 'df835a4e-1a33-4cb2-9fa9-a64393bcdf68',
          // 'data-blockingmode': 'auto',
          'data-culture': locale.value
        }
      ]
    }
  })
</script>

<template>
  <div class="app">
    <ClientOnly>
      <OverlaySpinner
        v-if="loading"
        size="lg"
      />
    </ClientOnly>
    <NavigationHeader @refresh-all-data="onLocaleChange" />
    <div class="title-wrapper content-wrapper">
      <div
        v-if="cmsData?.content?.pageName"
        class="page-title"
      >
        <Text
          :text="cmsData.content.pageName"
          type="h1"
        />
      </div>
    </div>

    <div
      v-if="!isDesktop"
      class="mobile-sidebar__container"
    >
      <AccordionMenu
        :title="activeMenu"
        class="mobile-sidebar__menu"
      >
        <template #menu="{ config }">
          <Menu
            :items="menuData"
            v-bind="config"
            @change="getPageData"
          />
        </template>
      </AccordionMenu>
    </div>

    <HeroBanner
      v-if="cmsData?.content?.heroImage?.id"
      :hero-card="cmsData.content.heroCard"
      :hero-image="cmsData.content.heroImage"
    />

    <NuxtLayout
      :class="cmsData?.content?.layout || 'default'"
      :name="cmsData?.content?.layout || 'default'"
      @change="getPageData"
    >
      <NuxtPage
        v-if="cmsData?.content"
        :content="cmsData.content"
      />
    </NuxtLayout>
    <MainFooter
      :groups="footerData?.menu"
      :social-media="footerData?.socials"
      class="footer"
    >
      <template #all-rights-reserved>
        <NuxtLink :to="footerData?.allRightsReserved?.href">
          {{ footerData?.allRightsReserved?.text }}
        </NuxtLink>
      </template>
      <template #contact>
        <NuxtLink
          :to="footerData?.contactUs?.href.url"
          class="capitalize"
        >
          {{ footerData?.contactUs?.text }}
        </NuxtLink>
      </template>
    </MainFooter>
  </div>
</template>

<style lang="scss">
  @import '@/assets/styles/common.scss';

  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    .footer {
      margin-top: auto;
    }
  }

  .content-wrapper {
    max-width: rem(1120);
    margin: auto;
    width: 100%;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto rem(16);

    @include desktop-up {
      margin: 0 auto rem(40);
    }
  }

  .page-title {
    font-size: rem(32);
    line-height: rem(38);
    color: $bt-blue;
    font-weight: 500;
    margin: auto rem(16) 0;

    @include desktop-up {
      font-size: rem(56);
      line-height: rem(66);
      margin: auto 0 rem(8);
    }
  }

  .events-tabs-filter {
    font-size: rem(16);
    line-height: rem(16);
    height: rem(48);

    @include tablet-up {
      margin-right: rem(16);
    }

    @include desktop-up {
      margin: 0;
    }

    &.mobile {
      padding: 0 rem(16);
      margin-bottom: rem(24);
    }
  }
  .mobile-sidebar {
    &__container {
      max-width: rem(1120);
      padding: 0 rem(16);
      margin: auto;
      width: 100%;
    }

    &__menu {
      margin-bottom: rem(24);

      @include tablet-up {
        margin-bottom: rem(32);
      }
    }
  }

  .capitalize {
    text-transform: uppercase;
    text-decoration: underline;
  }
</style>
