<script lang="ts" setup>
  import { LanguageSwitcher } from '@bt/design-system'
  import { availableLocales, setPathByLocale } from '@/composables/languageSwitcher'
  import type { languagesT } from '@/types/General/Languages'

  const emit = defineEmits(['refreshAllData'])

  const avLocales = computed(() =>
    availableLocales().map((l) => ({
      code: l.code as languagesT,
      title: l.title,
      isActive: l.isActive || false
    }))
  )

  const onLangChange = (value: string) => {
    setPathByLocale(value)
    emit('refreshAllData', value)
  }
</script>

<template>
  <LanguageSwitcher
    :languages="avLocales"
    @change="onLangChange"
  />
</template>
