import type MenuI from '@/types/Navigation/MenuI'
import type MenuItemI from '@/types/Navigation/MenuItemI'
import fetchGql from '@/utils/fetchGql'
import { useCacheStorage } from '@/composables/useSessionStorage'
import getInvestorMenuItems from '@/gql/getInvestorMenuItems.gql'

const { getCache } = useCacheStorage()

const parseData = (data: any, locale: string) => {
  const result: MenuI[] = []

  data[0].content.items.forEach((menuItem: MenuItemI) => {
    if (menuItem.subItems?.length) {
      const parsedItems: MenuI[] = []
      menuItem.subItems.forEach((item: MenuItemI) => {
        parsedItems.push({
          title: item.title,
          key: formatSbPath(item.redirect?.story?.full_slug, locale) || '/'
        })
      })
      result.push({
        title: menuItem.title,
        subItems: parsedItems,
        key: formatSbPath(menuItem.redirect?.story?.full_slug, locale) || '/'
      })
    } else {
      result.push({
        title: menuItem.title,
        key: formatSbPath(menuItem.redirect?.story?.full_slug, locale) || '/'
      })
    }
  })

  return result
}

export const useFetchMenu = () => {
  const fetchMenu = (language: string) => {
    const cache = getCache(`investors-menu-${language}`)

    const variables = {
      language: language === 'en' ? '' : `${language}/*`
    }

    if (cache.value) return cache.value

    return fetchGql(getInvestorMenuItems, variables).then((response: any) => {
      return (cache.value = parseData(response.MenuItems.items, variables.language))
    })
  }

  return { fetchMenu }
}
