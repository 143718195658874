import axios from 'axios'
const resolveRelations: string[] = ['StructuredFinancialReports.report']

export default class StoryBlok {
  static pull(path: string, locale: string) {
    const config = useRuntimeConfig()
    const version = config.public.env === 'production' ? 'published' : 'draft'

    const apiConfig: any = {
      version,
      language: locale,
      resolve_links: 'story',
      resolve_relations: resolveRelations.join(',')
    }

    return useAsyncStoryblok(`corporate/investors-airbaltic-com/${path}`, apiConfig)
      .then((response) => response.value)
      .catch((err) => {
        const error = JSON.parse(err)
        throw showError(error)
      })
  }

  static async pullStories() {
    const config = useRuntimeConfig()
    const version = config.public.env === 'production' ? 'published' : 'draft'
    const pageSize = 10
    let page = 1
    const url = `https://api.storyblok.com/v2/cdn/stories/?version=${version}&token=${config.public.storyblokToken}&per_page=${pageSize}&page=${page}&starts_with=corporate&excluding_slugs=corporate/shared*,corporate/company*`

    const response = await axios.get(url)

    const result: any = []

    if (response.status === 200) {
      result.push(...response.data.stories)
    }

    const pageCount = Math.ceil(response.headers.total / pageSize)

    if (pageCount > 1) {
      for (page = 2; page <= pageCount; page++) {
        const url = `https://api.storyblok.com/v2/cdn/stories/?version=${version}&token=${config.public.storyblokToken}&per_page=${pageSize}&page=${page}&starts_with=corporate&excluding_slugs=corporate/shared*,corporate/company*`

        const response = await axios.get(url)

        if (response.status === 200) {
          result.push(...response.data.stories)
        }
      }
    }

    return result
  }
}
