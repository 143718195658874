import type MenuI from '@/types/Navigation/MenuI'
import type MenuItemI from '@/types/Navigation/MenuItemI'
import fetchGql from '@/utils/fetchGql'
import { useCacheStorage } from '@/composables/useSessionStorage'
import getSharedMenuItems from '@/gql/getSharedMenuItems.gql'
import type { GetSharedMenuItemsQueryVariables } from '~/gqlTypes/types'

const { getCache } = useCacheStorage()

const parseData = (data: any, locale: string) => {
  const result: MenuI[] = []

  data[0].content.items.forEach((menuItem: MenuItemI) => {
    let target = '_self'

    const itemUrl = menuItem.redirect?.story?.full_slug ?? menuItem.redirect?.url
    if (menuItem.redirect?.target) {
      target = menuItem.redirect.target
    }

    result.push({
      title: menuItem.title,
      key: formatSbPath(itemUrl, locale) || '/',
      target
    })
  })

  return result
}

export const useFetchHeaderMenu = () => {
  const fetchHeaderMenu = (language: string) => {
    const cache = getCache(`header-nav-${language}`)

    const variables: GetSharedMenuItemsQueryVariables = {
      language: language === 'en' ? '' : `${language}/*`
    }

    if (cache.value) return cache.value

    return fetchGql(getSharedMenuItems, variables).then((response: any) => {
      cache.value = parseData(response.MenuItems.items, variables.language)
      return cache.value
    })
  }

  return { fetchHeaderMenu }
}
