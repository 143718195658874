import { useAppStore } from '@/stores'

export default async function (query: any, variables?: any, disableCache: boolean = false) {
  const appStore = useAppStore()
  appStore.loading = true

  let retries = 5
  let error = null

  let data = null

  while (retries > 0) {
    error = null

    const options = {
      query,
      variables
    }

    if (disableCache) {
      Object.assign(options, { cache: false })
    }

    data = await useAsyncQuery(options)
      .then((response) => response.data)
      .catch((e) => {
        error = { statusCode: e.status, fatal: true, message: e.message }
      })

    if (data?.value === null) {
      error = { statusCode: 500, fatal: true, message: 'Issue loading the data' }
    }

    if (error) {
      if (error.statusCode === 404) {
        appStore.loading = false

        throw showError(error)
      }

      retries--
    } else {
      break
    }
  }

  appStore.loading = false

  if (error) {
    throw showError(error)
  } else {
    return data?.value
  }
}
