<script lang="ts" setup>
  import { Card } from '@bt/design-system'
  const { isMobile } = useBreakpoints()

  const props = defineProps<{
    heroImage: any
    heroCard?: any
  }>()
</script>

<template>
  <div class="hero-banner">
    <NuxtImg
      :alt="props.heroImage.alt ?? ''"
      :title="props.heroImage.title ?? ''"
      :height="isMobile ? 240 : 480"
      :src="props.heroImage.filename"
      :width="isMobile ? 320 : 1600"
      format="webp"
      preload
    />
    <div
      v-if="props.heroCard"
      class="hero-banner-events"
    >
      <div class="event">
        <Card
          v-for="card in props.heroCard"
          :key="card.title"
          :subtitle="card.subTitle"
          :title="card.title"
        >
          <template
            v-if="card.buttons?.length > 0"
            #actions
          >
            <StoryblokComponent
              v-for="button in card.buttons"
              :key="button._uid"
              :blok="button"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .hero-banner {
    margin-bottom: rem(32);
    position: relative;

    @include desktop-up {
      margin-bottom: rem(40);
    }

    .event {
      margin: rem(32) rem(16);

      @include tablet-up {
        position: absolute;
        top: 0;
        bottom: 0;
        left: rem(16);
        right: 0;
        max-height: 480px;
        max-width: 1120px;
        margin: auto;
      }

      @include desktop-up {
        left: 0;
      }

      :deep {
        > div {
          background: white;
          margin: auto;

          @include tablet-up {
            margin: 0;
          }
        }
      }
    }

    img {
      height: 100%;
      max-height: 240px;
      object-fit: cover;
      width: 100%;

      @include tablet-up {
        max-height: 480px;
      }
    }
  }
</style>
