import { head } from 'lodash-es'
import fetchGql from '@/utils/fetchGql'
import ContentNodes from '@/gql/getContentNodes.gql'

export const useFetchSlugTranslation = () => {
  const fetchSlugTranslation = (slug: string) => {
    const variables = {
      slug
    }

    return fetchGql(ContentNodes, variables).then((response: any) => {
      return head(response.ContentNodes.items)
    })
  }

  return { fetchSlugTranslation }
}
